<template>
  <component :is="this.type + '-mark'"></component>
</template>

<script>
import PlasticMark from './MarkIcons/PlasticMark.vue';
import PaperMark from './MarkIcons/PaperMark.vue';
import AluminiumMark from './MarkIcons/AluminiumMark.vue';
import SteelMark from './MarkIcons/SteelMark.vue';
import PetMark from './MarkIcons/PetMark.vue';

export default {
  components: {
    PlasticMark,
    PaperMark,
    AluminiumMark,
    SteelMark,
    PetMark,
  },
  props: {
    type: { type: String, required: true, },
  },
};
</script>
