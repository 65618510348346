






















import LabelLayoutBase from "@/views/label/companies/print/layouts/label-layout-base";
import {Component} from "vue-property-decorator";

@Component
export default class extends LabelLayoutBase {

  private getPartners() {
    const values = this.getPartnerValues();
    return values.map(v => ({
      title: v.title,
      value: v.name ? `${v.name} ${v.address}` : v.address,
    }));
  }

}
