import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ProductEntity from "@/entities/product-entity";
import {BasicNutritionList} from '@/entities/nutrition-entity';
import CompanyEntity from "@/entities/company-entity";
import {NutritionValue} from "@/entities/nutrition-value";
import {
  doesNutritionLabelByFormatHasEstimation,
  getNutritionLabelByFormat
} from "@/entities/interfaces/i-label-nutrition";

@Component
export default class extends Vue {
  @Prop({required: true}) public product!:ProductEntity;
  @Prop({required: true}) public company!:CompanyEntity;
  protected readonly BasicNutritionList = BasicNutritionList;

  private get assortItems(): ProductEntity[] {
    return this.product.productItems.filter(pi => !!pi.childProduct).map(pi => pi.childProduct!);
  }
  private getLabelName(product: ProductEntity, noEstimation: boolean = false) {
    return getNutritionLabelByFormat(product.productLabel, noEstimation);
  }
  private get doesAllAssortProductContainsEstimation() {
    return this.product.productItems.filter(pi => !!pi.childProduct).every(pi => {
        return doesNutritionLabelByFormatHasEstimation(pi.childProduct!.productLabel.nutritionDisplayFormat)
    });
  }
  protected getNutrition(product: ProductEntity, key:string): string {
    const value:NutritionValue = product.getNutritionPerLabelAmount(key);
    return product.isNutritionOverride ?
      (value.original || '0') :
      value.formatForLabelPrint(this.company.getDecimalPoint(key));
  }
}
