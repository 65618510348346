































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import NutritionLayoutBase from "@/views/label/companies/print/layouts/nutrition/NutritionLayoutBase";

@Component
export default class extends NutritionLayoutBase {
}
