

















import {Component, Prop, Vue} from "vue-property-decorator";
import MarkIcon from "./MarkIcon.vue";
import ProductEntity from "@/entities/product-entity";
import groupBy from "lodash/groupBy";
import {MarkType} from "@/entities/product-label-identification-mark-entity";

@Component({
  components: {
    MarkIcon,
  }
})
export default class extends Vue  {
  @Prop({required: true}) public product!:ProductEntity;

  private readonly MarkType = MarkType;
  protected readonly MarkIconTypes = {
    [MarkType.Plastic]: 'plastic',
    [MarkType.Paper]: 'paper',
    [MarkType.PET]: 'pet',
    [MarkType.Steel]: 'steel',
    [MarkType.Aluminium]: 'aluminium',
  }

  protected get markIcons() {
    return groupBy(this.product.productLabel.identificationMarks.filter(im => !!im.markType), im => this.MarkIconTypes[im.markType]);
  }
}
