



















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import AuthViewBase from "@/views/AuthViewBase";
import LoadingHandler from '@/utils/loading-handler';

import ProductEntity from "@/entities/product-entity";

import BorderedTable from './layouts/BorderedTable.vue';
import BorderlessTable from './layouts/BorderlessTable.vue';
import InlineDelimitedByBall from './layouts/InlineDelimitedByBall.vue';
import {LayoutTypeKeys} from "@/entities/product-label-layout-entity";
import {getPrintPageStyle, setPrintPageStyle, wrapWithStyle} from "@/views/label/companies/print/page-style";
import {LabelSheetRepository} from "@/repositories/master/label-sheet-repository";
import {LabelSheetEntity} from "@/entities/label-sheet-entity";
import ProductRepository from "@/repositories/company/product-repository";
import CompanyEntity from "@/entities/company-entity";
import {CompanyRepository} from "@/repositories/company-repository";
import LabelPrintBrotherRepository from "@/repositories/company/label-print-brother-repository";
import {LabelPrintSettingType} from "@/entities/label-print-setting-entity";

import {sleep} from "@/utils/sleep";

@Component({
  components: {
    BorderedTable,
    BorderlessTable,
    InlineDelimitedByBall
  }
})
export default class extends AuthViewBase {
  @Prop({required: true}) type!: LabelPrintSettingType;
  @Prop({required: true}) labelSheetId!:number;
  @Prop({required: true}) items!:string; // productId1:count1,productId2:count2
  @Prop({required: true}) manufactureDateTimestamp!:number;

  private labelSheet:LabelSheetEntity = null as any;
  private products:{product:ProductEntity, count:number}[] = [];
  private company:CompanyEntity = null as any;
  private initialized = false;

  private get manufactureDate():Date {
    return new Date(Number(this.manufactureDateTimestamp));
  }

  private async created() {
    const items = this.items.split(",").map(item => {
      const s = item.split(":");
      if (s.length !== 2) return null;
      return { productId: Number(s[0]), count: Number(s[1])};
    }).filter(i => !!i);

    await LoadingHandler(async () => {
      await Promise.all([
        (new LabelSheetRepository()).find(this.labelSheetId).then(res => {
          this.labelSheet = res;
        }),
        (new CompanyRepository()).find(this.companyId).then(res => {
          this.company = res;
        }),
        ...items.map(i => {
          return (new ProductRepository(this.companyId)).findById(i!.productId).then(p => {
            this.products.push({ product: p, count: i!.count });
          });
        })
      ]);
    }, 20000, { text: 'データ取得中' });

    this.initialized = true;
  }

  private async mounted() {
    // this.handleZoom();

    const waitInitialized = () => new Promise(resolve => {
      const key = setInterval(() => {
        if(this.initialized) {
          clearInterval(key);
          resolve(null);
        }
      }, 50);
    });

    await waitInitialized();

    setPrintPageStyle(this.labelSheet);
    await this.$nextTick();
    await sleep(200);

    if (this.type === 'brother') {
      const domString = this.createHtml();
      // const pngs = await LoadingHandler(() => this.createPngs(), 60000, { text: '印刷用画像生成中...' });
      const brotherUrl = await LoadingHandler(() => this.getBrotherUrl(domString), 20000, { text: 'ファイル転送中...' });
      location.href = brotherUrl;
      return;
    } else {
      window.print();
    }

    if (this.isDev) return;

    await sleep(200);
    window.close();
  }

  private createHtml(): string {
    [...document.head.children].forEach((n: Element) => {
      if (n.tagName === 'LINK') {
        n.setAttribute('href', (n as HTMLLinkElement).href);
      }
      return n;
    });
    const layout = document.getElementById('layout')!;
    const body = document.createElement('body');
    body.appendChild(layout);

    const html = document.getElementsByTagName('html')[0];
    html.getElementsByTagName('body')[0].replaceWith(body);

    return html.outerHTML;
  }

  private async getBrotherUrl(html: string) {
    // 生成したPDFをサーバーにアップ
    const fileUrl = await (new LabelPrintBrotherRepository(this.companyId)).domToPdf(html);
    console.debug('fileUrl', fileUrl);
    const fileUrlEnc = encodeURIComponent(fileUrl);

    // url生成
    // https://support.brother.co.jp/j/s/es/dev/ja/specific/smooth_print/index.html?c=jp&lang=ja&comple=on&redirect=on
    const option = "copies=1&orientation=portrait&halftone=threshold&rjDensity=5";
    const bin = `size=https%3A%2F%2Fs3.ap-northeast-1.amazonaws.com%2Fpublic.smashoku.com%2Fbrother%2F${this.labelSheet.brotherBin}.bin`;
    return `brotherwebprint://print?filename=${fileUrlEnc}&${bin}&${option}`;
  }

  private handleZoom() {
    const resize = function () {
      const zoom = Math.round((window.outerWidth / window.innerWidth) * 100);
      (document.body.style as any).zoom = `calc(100 / ${zoom})`;
      // console.debug('zoom', { zoom: zoom, outerWidth: window.outerWidth, innerWidth: window.innerWidth });
    };
    resize();
    window.addEventListener('resize', resize, false);
  }

  private get isDev():boolean {
    return this.$route.query.hasOwnProperty('dev');
  }

  private get productsFlatten(): ProductEntity[] {
    const ret:ProductEntity[] = [];
    for(let p of this.products) {
      for (let i = 0; i < p.count; i++) {
        ret.push(p.product);
      }
    }
    return ret;
  }
  private getProduct(page:number, itemIndex: number) {
    return this.productsFlatten[this.getOffset(page, itemIndex)];
  }
  private get perPage():number {
    return this.labelSheet!.countPerPage;
  }
  //
  private get pageCount(): number {
    return Math.ceil(this.productsFlatten.length / this.perPage);
  }
  private getOffset(page, itemIndex) {
    return ( (page - 1) * this.perPage) + (itemIndex - 1);
  }

  private getLayout(product:ProductEntity) {
    return LayoutTypeKeys[product.productLabelLayout.layoutType];
  }

}
