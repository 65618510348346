import RepositoryBase from "@/repositories/repository-base";
import UserEntity from "@/entities/user-entity";

export default class LabelPrintBrotherRepository extends RepositoryBase<UserEntity> {
  public constructor(companyId:string|number) {
    super();
    this.endpoint = `companies/${companyId}/label-print-brother`
  }

  public async domToPdf(html: string): Promise<string>  {
    const res = await this.post(this.endpoint + '/dom-to-pdf', {
      html: html
    });
    return res.data as string;
  }
}
