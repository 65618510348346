import ObjectUtils from "@/utils/object-utils";

export class LabelSheetEntity {
  public id!:number;
  public name!:string;

  public sheetWidth!:number;
  public sheetHeight!:number;
  public sheetPaddingTop!:number;
  public sheetPaddingRight!:number;
  public sheetPaddingBottom!:number;
  public sheetPaddingLeft!:number;
  public colCount!:number;
  public rowCount!:number;
  public itemWidth!:number;
  public itemHeight!:number;
  public itemPaddingTop!:number;
  public itemPaddingRight!:number;
  public itemPaddingBottom!:number;
  public itemPaddingLeft!:number;

  public brotherBin!: string | null;

  public get countPerPage(): number {
    return this.colCount * this.rowCount;
  }

  public get itemPadding(): string {
    return `${this.itemPaddingTop}mm ${this.itemPaddingRight}mm ${this.itemPaddingBottom}mm ${this.itemPaddingLeft}mm`;
  }

  constructor(init:LabelSheetEntity) {
    ObjectUtils.assignLiteralFields(this, init);
  }

  public get forBrother(): boolean {
    return this.brotherBin !== null;
  }

}

