import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import {LabelSheetEntity} from "@/entities/label-sheet-entity";

export class LabelSheetRepository extends RepositoryBase<any> {
  public constructor() {
    super();
    this.endpoint = `label-sheets`;
  }

  public async all(): Promise<LabelSheetEntity[]>  {
    const res = await this.get(this.endpoint);
    return res.data.map(d => new LabelSheetEntity(d));
  }

  public async find(id:number): Promise<LabelSheetEntity>  {
    const res = await this.get(this.endpoint + `/${id}`);
    return new LabelSheetEntity(res.data);
  }
}
