import * as Ja from './ja/label-print';
import * as En from './en/label-print';
import * as Cn from './cn/label-print';
import {getTextByLang} from "@/bootstraps/locale";

export const help = {
  name: getTextByLang({
    ja: Ja.name,
    en: En.name,
    cn: Cn.name,
  }),
};
