





























import LabelLayoutBase from "@/views/label/companies/print/layouts/label-layout-base";
import {Component} from "vue-property-decorator";

@Component
export default class extends LabelLayoutBase {
  private fontSize: string = '5.5pt';
  created() {
    this.fontSize = this.layout.fontSize + 'pt';
  }
}
