


























import {BasicNutritionList} from '@/entities/nutrition-entity';

import LabelLayoutBase from "@/views/label/companies/print/layouts/label-layout-base";
import {Component} from "vue-property-decorator";

@Component
export default class extends LabelLayoutBase {
  private readonly BasicNutritionList = BasicNutritionList;
}
