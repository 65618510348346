import ObjectUtils from "@/utils/object-utils";
import {
  createMaxStringRule,
  createMinRule, getTypeIntRule,
  requiredRule, typeNonNegativeIntRule,
} from "@/utils/validation-rules";
import { LabelSheetEntity } from "./label-sheet-entity";
import ProductEntity from "@/entities/product-entity";

export class LabelPrintSettingEntity {
  public id!:number;
  public name!:string;

  public labelSheetId!:number;
  public labelSheet!:LabelSheetEntity;
  public labelPrintSettingProducts:LabelPrintSettingProductEntity[] = [];

  public type: LabelPrintSettingType = 'default';

  public get isDirty() {
    return !this.id;
  }

  public get isBrother() {
    return this.type === 'brother';
  }

  constructor(init:Partial<LabelPrintSettingEntity>) {
    ObjectUtils.assignLiteralFields(this, init);

    if(init.labelSheet) {
      this.labelSheet = new LabelSheetEntity(init.labelSheet);
    }
    if(init.labelPrintSettingProducts) {
      this.labelPrintSettingProducts = init.labelPrintSettingProducts.map(p => new LabelPrintSettingProductEntity(p));
    }
  }
}

export type LabelPrintSettingType = 'default' | 'brother';

export class LabelPrintSettingProductEntity {
  public productId!:number;
  public product!:ProductEntity;
  public count:number = 0;

  constructor(init:LabelPrintSettingProductEntity) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.product) {
      this.product = new ProductEntity(init.product);
    }
  }
}


const LabelPrintSettingProductEntityValidatorRules:any = {
  productId: [requiredRule],
  count: [requiredRule, typeNonNegativeIntRule],
};

export const LabelPrintSettingEntityValidatorRules:any = {
  name: [requiredRule, createMaxStringRule()],
  labelSheetId: [requiredRule],
  labelPrintSettingProducts: LabelPrintSettingProductEntityValidatorRules
};
