<template>
  <transition name="el-fade-in">
    <span v-if='value'>
      <slot/>
    </span>
  </transition>
</template>

<script>
export default {
  props: {
    value: { type: Boolean },
    duration: { type: Number, default: 1000},
  },
  watch: {
    value() {
      setTimeout(() => {
        this.$emit('input', false);
      }, this.duration);
    }
  },
}
</script>

