import {LabelSheetEntity} from "@/entities/label-sheet-entity";

export function getPrintPageStyle(sheet: LabelSheetEntity) {
  return `
@page {
    size: ${sheet.sheetWidth}mm ${sheet.sheetHeight}mm;
    margin: 0;
}
@media all {
  body {
    width: ${sheet.sheetWidth}mm;
  }
}
.page {
  width: ${sheet.sheetWidth}mm;
  height: calc(${sheet.sheetHeight}mm - 2px); /** はみでないよう調節 **/
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  padding-top: ${sheet.sheetPaddingTop}mm;
  padding-right: ${sheet.sheetPaddingRight}mm;
  padding-bottom: ${sheet.sheetPaddingBottom}mm;
  padding-left: ${sheet.sheetPaddingLeft}mm;
  overflow: hidden;
  box-sizing: border-box;
  border: 0;
  outline: none;
}
.page + .page {
  page-break-before: always;
}
.page .label-container {
  width: ${sheet.itemWidth}mm;
  height: ${sheet.itemHeight}mm;
  overflow: hidden;
  padding: ${sheet.itemPadding};
}
`.replaceAll("\n", '');
}

export function wrapWithStyle(css: string) {
  const style = document.createElement('style');
  style.innerText = css;
  return style;
}

export function setPrintPageStyle(sheet: LabelSheetEntity) {
  const css = getPrintPageStyle(sheet);
  const style = wrapWithStyle(css);
  document.head.appendChild(style);
}
